<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <template v-if="activeName === 'CATEGORY'">
        <div class="subject-list">
          <div class="subject-item" v-for="item in customizeList" :key="item.id" @click="handleClick(item)">
            <div class="subject-cover">
              <img :src="`${OSS_URL}/${item.picture}`" alt="" />
            </div>
            <div class="subject-introduction">
              <span class="subject-name">{{ item.name }}</span>
              <span class="subject-total">共{{ item.contentCount }}个</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import navRouter from '@/components/navRouter'
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import { openUrl } from '@/utils/windowOpen'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'
import { API_MODULE } from '@/request/api'
export default {
  name: 'Customize',

  components: {
    menuPop,
    navRouter,
    back
  },
  data() {
    return {
      // 选中名称 分类、栏目、内容
      activeName: undefined,
      icon: redIcon,
      customizeList: [],
      id: undefined
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.GET(API_MODULE.SEARCH_CATEGORY_OR_CONTENT, { appId: this.templateId })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.activeName = res.data.type
            this.customizeList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick(item) {
      if (item.externalLinksUrl) {
        openUrl(item.externalLinksUrl)
      } else {
        this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [item.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: item.id
                }
                // music: item.music
              })
              // const path = `/layout/customize/${res.data.type}?id=${item.id}#music=${item.music}`
              const path = `/layout/customize/${res.data.type}?id=${item.id}`
              this.useRouterList.push({
                name: item.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
    } else {
      this.icon = blueIcon
    }
    // 从应用进入
    this.templateId = this.$route.query.appId
    this.getData()
    this.setRouterList([{ name: '自定义应用', path: this.$route.fullPath }])
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/themes/_handle.scss';
.box {
  .content {
    min-height: 13rem;
    box-sizing: border-box;
    @include background_color('background-medium');

    .subject-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 2.76rem;

      .subject-item {
        margin-right: 1.6rem;
        margin-top: 1.26rem;
        position: relative;

        .subject-cover {
          width: 5.62rem;
          height: 5.38rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .subject-introduction {
          padding: 0 0.4rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.6rem;
          background-color: rgba(204, 167, 102, 0.6);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .subject-name {
            width: 3.6rem;
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
            font-size: 0.36rem;
            font-weight: 600;
            color: #ffffff;
            opacity: 1;
            text-align: left;
          }

          .subject-total {
            font-size: 0.24rem;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>